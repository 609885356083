<template>
  <div>
    <div class="buttons">
      <b-button
        label="Nuevo"
        type="is-success"
        icon-left="pen-plus"
        @click="modalAltaSucu=true"
      />
    </div>
    <b-modal v-model="modalAltaSucu">
      <section class="box">
        <b-field label="Nombre" label-position="on-border">
          <b-input v-model="datosSucursal.nombreSucursal"></b-input>
        </b-field>
        <b-field label="Usuario" label-position="on-border">
          <b-input v-model="datosSucursal.nombreUsuarioSucursal"></b-input>
        </b-field>
        <b-field label="Contraseña" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia1" password-reveal></b-input>
        </b-field>
        <b-field label="Re-Contraseña" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia2" password-reveal></b-input>
        </b-field>
        <b-field label="Programación musica">
          <div class="block">
            <b-radio v-for="programacion in optionsRadio" :key="programacion.item" :native-value="programacion.item" name="progMusic" v-model="datosSucursal.radioSelected">
              {{ programacion.name }}
            </b-radio>
          </div>
        </b-field>
        <b-field label="Programación spot">
          <div class="block">
            <b-radio v-for="spot in optionsSpot" :key="spot.item" :native-value="spot.item" name="progSpot" v-model="datosSucursal.spotSelected">
              {{ spot.name }}
            </b-radio>
          </div>
        </b-field>
        <b-field label="Premisos para subir y programar spot">
          <div class="block">
            <b-radio :native-value="0" name="permSpot" v-model="datosSucursal.permisoSpots">
              No, subir y programar
            </b-radio>
            <b-radio :native-value="1" name="permSpot" v-model="datosSucursal.permisoSpots">
              S&iacute;, subir y programar
            </b-radio>
          </div>
        </b-field>
        <b-button
                label="Guardar"
                type="is-danger"
                icon-left="check"
                v-on:click="guardarSucursal"/>
      </section>
    </b-modal>
  </div>
</template>

<script>
import SucursalServices from '../../services/SucursalServices'
import UserServices from '../../services/UserServices'
import ClienteServices from '../../services/ClienteServices'
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../components/NotificationPlugin/NotificationTemplate'
import ClienteProgramacion from '../../services/ClienteProgramacionServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import Modal from '../../components/Modal.vue'
import SpotServices from '../../services/SpotServices'
import RadioServices from '../../services/RadioServices'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    ScaleLoader,
    Modal
  },
  props: {
    modalAltaSucu: {
      type: Boolean,
      default: false
    },
    modalAltaClie: {
      type: Boolean,
      default: false
    },
  },
  data () {
    const i18labels = this.$t('modulo_sucursales.AltaSucursal')
    return {
      titulos: {
        titulo: i18labels.titulo,
        subtituloDatos: i18labels.subtituloDatos,
        subtituloProgramaciones: i18labels.subtituloProgramaciones,
        subtituloProgSpots: i18labels.subtituloProgSpots,
        subtituloProgMusica: i18labels.subtituloProgMusica
      },
      datosSucursal: {
        idSucursal: -1,
        nombreSucursal: '',
        nombreUsuarioSucursal: '',
        clientePrefijo: '',
        contrasenia1: '',
        contrasenia2: '',
        radioSelected: 0,
        spotSelected: 0,
        permisoSpots: 0
      },
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger'],
      notifications: {
        topCenter: false
      },
      optionsRadio: [],
      optionsSpot: [],
      isLoading: false,
      pasoWizard: 0,
      showModal: false,
      nombreProgramacion: '',
      tipoProgramacion: '',
      cantProgSpot: null,
      cantProgRadio: null,
      permisoSelected: 0
    }
  },
  methods: {
    altaProgramacionMusica () {
      // servicio para guardar una programacion de radio
      this.isLoading = true
      // el primer parametro es 0 para crear una nueva
      RadioServices.altaProgramacion(0, this.nombreProgramacion)
        .then(res => {
          this.showModal = !this.showModal
          this.listarProgramacionesRadio()
          this.isLoading = false
        })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.isLoading = false
        })
    },
    altaProgramacionSpot () {
      // servicio para guardar un parogramacion de spot
      this.isLoading = true
      SpotServices.altaProgramacion(0, this.nombreProgramacion)
        .then(res => {
          // si todo sale ok, redirijo a programaSpot
          this.showModal = !this.showModal
          this.listarProgramacionesSpot()
          this.isLoading = false
        })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.isLoading = false
        })
    },
    btnGuardar () {
      if (this.idSucursal === 0) {
        this.guardarSucursal()
      } else {
        this.editarSucursal()
      }
    },
    guardarSucursal () {
      if (this.datosSucursal.radioSelected != 0 && this.datosSucursal.spotSelected != 0) {
        if (this.datosSucursal.nombreSucursal.length > 0 && this.datosSucursal.nombreUsuarioSucursal.length > 0 && this.datosSucursal.contrasenia1.length > 0) {
          if (this.datosSucursal.contrasenia1 === this.datosSucursal.contrasenia2) {
            this.isLoading = true
            SucursalServices.altaSucursal(this.datosSucursal.idSucursal, this.datosSucursal.nombreSucursal, this.datosSucursal.nombreUsuarioSucursal, this.datosSucursal.clientePrefijo,
              this.datosSucursal.contrasenia1, this.datosSucursal.radioSelected, this.datosSucursal.spotSelected, this.datosSucursal.permisoSelected)
              .then(res => {
                // si todo sale ok, redirijo a sucursales
                this.isLoading = false
                this.$router.push({ path: '/sucursales' })
              })
              .catch(err => {
                this.handleError(err)
                this.notifyVue('top', 'center', this.notificacion.mensaje)
                this.isLoading = false
              })
          } else {
            this.notificacion.mensaje = 'Las contraseñas no coinciden'
            this.notifyVue('top', 'center', this.notificacion.mensaje)
          }
        } else {
          this.notificacion.mensaje = 'Debe completar todos los campos'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.pasoAnterior()// vuelvo al paso anterior del wizard
        }
      } else {
        this.notificacion.mensaje = 'Debe seleccionar las programaciones'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },

    getPrefijo () {
      ClienteServices.getClienteByUsername().then(response => {
        this.clientePrefijo = response.cli_prefijo + '_'
      })
    },
    editarSucursal () {
      // Obtengo por parametro el codigo de la sucursal para editar, 0 si es para crear una nueva
      if (this.nombreSucursal.length > 0 && this.idSucursal!=0 && this.contrasenia1.length > 0) {
        if (this.contrasenia1 === this.contrasenia2) {
          this.isLoading = true
          SucursalServices.put(this.idSucursal, this.nombreSucursal, this.nombreUsuarioSucursal, this.contrasenia1)
            .then(res => {
              // si todo sale ok, redirijo a sucursales
              this.isLoading = false
              this.$router.push({ path: '/sucursales' })
            })
            .catch(err => {
              this.handleError(err)
              this.notifyVue('top', 'center', this.notificacion.mensaje)
              this.isLoading = false
            })
        } else {
          this.notificacion.mensaje = 'Las contraseñas no coinciden'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        }
      } else {
        this.notificacion.mensaje = 'Debe completar todos los campos'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },
    verificarParametros () {
      const sucNombre = this.$route.params.nombreSucursal
      const sucUsuario = this.$route.params.sucUsuario

      if (this.idSucursal != 0) {
        this.nombreSucursal = sucNombre
        this.nombreUsuarioSucursal = sucUsuario
      }
    },
    listarProgramacionesRadio () {
      const resJson = []
      ClienteProgramacion.listarProgRadios().then(res => {
        const data = JSON.parse(localStorage.listProgRadio)
        data.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsRadio = resJson
        this.cantProgRadio = this.optionsRadio.length
        this.isLoading = false
      })
    },
    listarProgramacionesSpot () {
      const resJson = []
      ClienteProgramacion.listarProgSpot().then(res => {
        const data = JSON.parse(localStorage.listProgSpot)
        data.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsSpot = resJson
        this.cantProgSpot = this.optionsSpot.length
        this.isLoading = false
      })
    },
    handleError (err) {
      if (!err.response) {

        this.notificacion.mensaje = err.response
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {

          switch (err.response.status) {
            case 502:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
                *luego, de la primer parte, con un substr para extraer lo que sigue de
                *"system.exception" (17 caracteres)
                */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    },
    onInput (evt) {
      this.$emit('input', evt.target.value)
    }

  },
  created () {
    this.verificarPermisoAlta()
  },
  mounted () {
    this.i18n = this.$i18n
    this.getPrefijo()
    this.verificarParametros()
    this.listarProgramacionesRadio()
    this.listarProgramacionesSpot()
  },
  computed: {
    listeners () {
      return {
        input: this.onInput
      }
    },
    estadoBoton () {
      // si no tiene prefijo, no puede enviar el formulario
      let inhabilitado = true
      if (this.clientePrefijo != null) {
        inhabilitado = false
      }
      return inhabilitado
    }
  }
}

</script>
<style>
</style>
