<template>

  
  <b-tabs :expanded="true" type="is-toggle" class="is-mobile">
    <b-tab-item label="Spots" icon="library">
        <b-loading :is-full-page="$isLoadFullPage" v-model="isLoading" :can-cancel="true"></b-loading>

        <!-- Inicio de tabla de blioteca -->
     <b-table class="is-dark" :sticky-header="true"  checkable cellClass="is-sticky-column-one" checkbox-type="is-info"
      :checked-rows.sync="spotSelected" :data="rows" sort-icon="arrow-up"
      sort-icon-size="is-small">
      <template #bottom-left>
        <div class="buttons">
          <b-button label="Nuevo" type="is-success" icon-left="pen-plus" @click="redirect" />
          <b-button label="Programar spots" type="is-success" icon-left="pen-plus" @click="slot=-1" />
          <b-button label="Editar" type="is-white" icon-left="pencil" @click="btnEditar"
            v-show="spotSelected.length == 1" />
          <b-button label="Eliminar" type="is-danger" icon-left="delete" @click="btnEliminar"
            v-show="spotSelected.length > 0" />
        </div>
      </template>
      <b-table-column :sortable=true field="spo_nombre" :searchable="true" label="Nombre" v-slot="props">
        {{ props.row.spo_nombre }}
      </b-table-column>
      <b-table-column :sortable=true field="spo_tipo" label="Categoria" v-slot="props">
        <span :class="`tag ${props.row.spo_tipo}`">
          {{ categSpot[props.row.spo_tipo] }}
        </span>
      </b-table-column>
      <b-table-column :sortable=true field="vencido" label="Vencimiento" v-slot="props">
        <span :class="`tag ${(props.row.vencido) ? 'is-danger' : 'is-success'}`">{{ props.row.spo_fecfin }}</span>
      </b-table-column>
      <b-table-column field="spo_source_src" label="Audio" v-slot="props">
        <audio controls :src="props.row.spo_source_src" class="tag">{{ props.row.spo_fecfin }}</audio>
      </b-table-column>
    </b-table>
      </b-tab-item>
      <!-- Inicio de tabs para programar -->
      <b-tab-item label="Programar">
      <nav class="level is-align-content-center">
      <div class="column is-block-desktop is-block-mobile">
        <b-field grouped group-multiline label="Seleccione spots y horario" class="is-block-desktop is-block-mobile">

                <b-taginput
                    v-model="spotSelectedProg"
                    :check-infinite-scroll="true"

                    style="width: fit-content;"
                    :data="rows"
                    maxtags="5"
                    @input="horarioHabil"
                    @add="updateSlot($event)"
                    autocomplete
                    ref="spots"
                    :open-on-focus="true"
                    >
                    <template slot-scope="props">
                      {{ props.option.spo_nombre }}
                    </template>
                    <template #selected="props">
                      <b-tag
                      ellipsis
                      v-for="(tag,i) in props.tags"
                      :key="i"
                      type="is-dark"
                      :tabstop="false"
                      closable
                      @close="$refs.spots.removeTag(i,$event)"
                      >
                      {{ tag.spo_nombre }}
                      </b-tag>
                    </template>   
                </b-taginput>
                <b-taginput
                    style="width: fit-content;"
                    v-model="rangoHorario"
                    :data="horarios.map(hora=>hora.text)"
                    type="is-dark"
                    :open-on-focus="true"
                    >
                </b-taginput>
            <b-select icon="radio" v-model="reprodSelected">
              <option :value="usuario.unique_name">
                Todos
              </option>
              <option v-for="(reprod, i) in sucursales" :value="reprod.clisuc_nombre" :key="i">
                {{ reprod.clisuc_nombre }}
              </option>
            </b-select>
            <b-select icon="calendar-weekend" v-model="dia">
              <option value="7">
                Toda la semana
              </option>
              <option v-for="(dia, i) in filtroSemana" :value="dia.value" :key="i">
                {{ dia.text }}
              </option>
            </b-select>
            <div class="control">
            <b-button
          label="Programar"
          type="is-dark"
          icon-left="calendar-plus"
          @click="setProgramaSemanal"
        /></div>
          </b-field>
    
  </b-field>
  
        
   

      </div>

    </nav>
        <b-table class="is-dark" :data="spotProgramados" checkable
        :sticky-header="true"  cellClass="is-sticky-column-one" checkbox-type="is-info"
      :checked-rows.sync="spotSelectedProg" sort-icon="arrow-up"
      sort-icon-size="is-small"
        >
        <template #bottom-left>
          
        <!-- {
    "clprsp_codigo": 14989,
    "clprsp_codigoProgramacion": 33,
    "clprsp_codigoSpot": 3,
    "clprsp_numeroDia": 0,
    "clipro_codigoCliente": 2,
    "clprsp_horaDesde": "08:00:00",
    "clprsp_horaHasta": "08:05:00",
    "clprsp_orden": 3,
    "spo_codigo": 0,
    "spo_nombre": "Esta es Tú F5",
    "spo_codigoColor": null,
    "spo_tipo": "inst",
    "spo_fecfin": "9999-02-01T00:00:00",
    "clprsp_usuario": "laf5",
    "clprsp_pautaAdmin": 0,
    "vencido": -1
} -->
        </template>
        <b-table-column :sortable="true" field="clprsp_numeroDia" :searchable="true" label="Día" v-slot="props">
          <span :class="`tag is-dark`">{{ filtroSemana.find(dia=>dia.value==props.row.clprsp_numeroDia).text + ' ( '+props.row.clprsp_horaDesde+' )' }}</span>
        </b-table-column>
      <b-table-column  field="spo_nombre" :searchable="true" label="Spot" v-slot="props">
        {{ props.row.spo_nombre }}
      </b-table-column>
      <b-table-column :sortable="true" field="clprsp_horaDesde" :searchable="true" label="Categoria" v-slot="props">
        <span :class="`tag ${props.row.spo_tipo}`">
          {{ categSpot[props.row.spo_tipo] }}
        </span>
      </b-table-column>
      <b-table-column :sortable="true" field="clprsp_usuario" :searchable="true" label="Para" v-slot="props">
        <span class="tag is-text">{{ (JSON.parse(usuario.Cliente).cli_usuari==props.row.clprsp_usuario)?'Todos':props.row.clprsp_usuario }}</span>
      </b-table-column>
      
    </b-table>
      </b-tab-item>
    </b-tabs>
  
</template>
<script>
import clienteProgramacionSpotService from '@/services/ClienteProgramacionSpotServices'
import SpotServices from '@/services/SpotServices'
import SucursalServices from '@/services/SucursalServices'
import UserService from '@/services/UserServices'
import moment from 'moment'
import clienteProgramacionService from '@/services/ClienteProgramacionServices'

export default {
  components: {

  },
  data () {
    return {
      title: 'Mis Spots',
      usuario: {},
      slot: null,
      rangoHorario: [],
      spotProgramados:[],
      iniHorario: [],
      slotOrigin: null,
      slotDest: null,
      diasHabiles: [],
      period: null,
      horarios: [],
      dia: 7,
      reprodSelected: null,
      spotSelected: [],
      spotSelectedProg: [],
      codigoProgramacion: null,
      programas: [],
      progSpots: [],
      sucursales: [],
      filtroSemana: [
        { text: 'Domingo', value: 0 },
        { text: 'Lunes', value: 1 },
        { text: 'Martes', value: 2 },
        { text: 'Miércoles', value: 3 },
        { text: 'Jueves', value: 4 },
        { text: 'Viernes', value: 5 },
        { text: 'Sabado', value: 6 }
      ],
      rows: [],
      categSpot: {
        inst: 'Institucional',
        prom: 'Promocional',
        noti: 'Noticias'
      },
      pathAltaSpot: 'Cargá tu spot',
      isLoading: false
    }
  },
  created () {
  },
  mounted () {
    this.getCurrentUser()
    this.getSpotsByCli()
    this.setSucursales()
    
    this.i18n = this.$i18n
  },
  methods: {
    /** Funciones para Drag and drop */
    dragstart (payload) {
      this.slotOrigin = payload.row.slot
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      this.slotDest = payload.slot
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      this.slotDest = payload.row.slot
      const indexDest = this.spotSelected.findIndex(spot => spot.slot == this.slotDest)
      this.spotSelected[indexDest].slot = this.slotOrigin
      const indexOrigin = this.spotSelected.findIndex(spot => spot.slot == this.slotOrigin)
      this.spotSelected[indexOrigin].slot = this.slotDest
      payload.event.target.closest('tr').classList.remove('is-selected')
    },
    /** Fin */
    setSucursales () {
      return clienteProgramacionService.listarProgSpot().then(() => {
        SucursalServices.getcliSucursalByCliente().then(async (result) => {
          this.sucursales = result
          this.progSpots = JSON.parse(localStorage.listProgSpot)
          if (this.progSpots.length == 1) {
            this.codigoProgramacion = this.progSpots[0].clipro_codigo
            
            this.spotProgramados = await clienteProgramacionSpotService.getProgramacionesByProg(this.codigoProgramacion, '00:00')
          }
        })
      })
    },
    getCurrentUser () {
      this.diasHabiles = JSON.parse(localStorage.diaHabil).map(dia => dia.cliDha_codigoDia)
      this.usuario = UserService.current()
      this.reprodSelected = this.usuario.unique_name
    },
    
    horarioHabil () {
      const horariosHabil = JSON.parse(localStorage.clienteHorario)
      let horaInit = moment(horariosHabil.cliHor_horaDesde, 'LT').toDate()
      this.horarios = [{ text: moment(horaInit, 'LT').format('HH:mm'), value: horaInit }]
      const horaFin = moment(horariosHabil.cliHor_horaHasta, 'LT').toDate()
      
      const dif = (ini, fin) => {
        const r = moment(ini, 'LT').diff(fin, 's')
        return r
      }
      const totalDurationSpot = 300 + this.spotSelectedProg.map(spot => spot.spo_dursec).reduce((acum, currentvalue) => acum + currentvalue, 0)
      while (dif(horaInit, horaFin) < 0) {
        horaInit = moment(horaInit, 'LT').add(totalDurationSpot, 's').toDate()
        this.horarios.push({ text: moment(horaInit, 'LT').format('HH:mm'), value: horaInit })
      }
      let horariosOcupados = this.spotProgramados.map(spoProg => spoProg.clprsp_horaDesde)
     
     
      debugger
     
      
    },
    setProgramaSemanal () {
      let semana = []

      if (this.dia == 7) {
        semana = this.diasHabiles.filter(dia => dia < 7)
      } else {
        semana[0] = this.dia
      }

        this.spotSelectedProg.forEach((spot) => {
          semana.filter(dia => dia <= this.dia).forEach((dia) => {
            this.rangoHorario.map(hora => moment(hora, 'LT').format('HH:mm')).forEach((hora) => {
              const programa = {
                codigoProgramacion: this.codigoProgramacion,
                codigoSpot: null,
                horaDesde: null,
                numeroDia: null,
                slot: null,
                codigoProgSpotDestino: 0,
                for: null
              }
              programa.horaDesde = hora
              programa.codigoSpot = spot.spo_codigo
              programa.numeroDia = dia
              programa.slot = parseInt(spot.slot)
              programa.for = this.reprodSelected
              this.programas.push(programa)
            })
          })
        })
      
      clienteProgramacionSpotService.guardarProgSpot(this.programas).then(result => { debugger })
    },
    updateSlot (checkedList) {
      debugger
      const key = this.spotSelectedProg.length-1
        checkedList.slot = key
    },
    getSpotsByCli () {
      this.isLoading = true
      SpotServices.getSpots().then(list => {
        this.rows = list
        localStorage.spots = JSON.stringify(this.rows)
        this.isLoading = false
      })
    },
    redirect () {
      this.$router.push({
        name: this.pathAltaSpot,
        params: {}
      })
    },
    btnEditar () {
      const item = this.spotSelected[0]
      this.$router.push({
        name: this.pathAltaSpot,
        params: item
      })
    },
    btnEliminar () {
      if (confirm('¿Esta seguro de eliminar el spot?')) {
        this.isLoading = true
        const deletePromises = this.spotSelected.map(spot => SpotServices.bajaSpot(spot.spo_codigo))
        Promise.all(deletePromises).then((resp) => {
          const result = []
          resp.forEach(r => {
            result.push(r)
          })
          return result.join(', ')
        }).then((result) => {
          this.$buefy.toast.open(`Spot eliminado: ${result}`)
          this.getSpotsByCli()
        })
      }
    }
  }// cierre methods
}
</script>
<style>
.tag.noti {
  background-color: brown;
  color: white;
}

.tag.inst {
  background-color: lightskyblue;
  color: white;
}

.tag.prom {
  background-color: yellowgreen;
  color: white;
}
</style>
