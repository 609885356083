import axios from 'axios'

// Configuración por defecto del objeto axios
// a que api nos conectamos para obtener datos
const url = 'https://api.elevenlabs.io/v1/';

const instance = axios.create({
  baseURL: url
})
instance.interceptors.request.use(
  (config) => {
    config.maxBodyLength = Infinity
    config.headers={
      accept:'audio/mpeg',
      'Content-Type': 'application/json', 
      'xi-api-key':'57a827bc782b9525fa20bb64087a7454'
      //'xi-api-key':'c502191eb5654eb272120cc5ecf6d55e' esta es la cuenta oficial clubfok
  }
  config.responseType = 'arraybuffer'
  return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
