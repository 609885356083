<template>
<b-card no-body>

    <b-form-group
      id="search-sucu"
      description="Buscador por nombre de sucursal"
      label="Buscar"
      label-for="input-search-sucu"
      invalid-feedback="No existe ninguna coincidencia"
      :state="rows.length>0"
    >
      <b-form-input id="input-search-sucu" class="bg-dark" v-model="filterSucu" trim></b-form-input>

    </b-form-group>

<b-row>
  <b-col class="text-white">
    <b-table sort-icon-left hover :items="rows" :filter="filterSucu" :fields="columns" responsive="sm" show-empty>
      <template #head(actions)>
            <b-button
            variant="dark"
            :pressed.sync="formAbm"
            title="Agregar sucursal"
            @click="btnAdd()">
            <b-icon-plus></b-icon-plus>
            </b-button>
      </template>
      <template #cell(actions)="row">
      <b-button-group >
            <b-button
            variant="dark"
            title="Editar sucursal"
            @click="btnEditar(row.item, $event.target)">
            <b-icon-pencil-fill></b-icon-pencil-fill>
            </b-button>

            <b-button
            title="Eliminar sucursal"
            variant="dark"
            @click="btnEliminar(row.item, $event.target)">
            <b-icon-trash-fill></b-icon-trash-fill>
            </b-button>

            <b-button
            title="Cambiar contraseña"
            variant="dark"
            @click="btnPasswordChange(row.item)"
            >
            <b-icon-key-fill></b-icon-key-fill>
            </b-button>
            <b-button :pressed="false" variant="dark" size="sm" @click="row.toggleDetails">
              <b-icon-info-circle-fill></b-icon-info-circle-fill>
            </b-button>
          </b-button-group>

    </template>
    <template #row-details="row">
      <b-table hover :items="[row.item]" stacked=""></b-table>
    </template>

  </b-table>
  </b-col>
  <b-col cols="5" v-if="formAbm">
    <b-form-group
     label-size="lg"
     label-class="font-weight-bold pt-0"
     class="mb-0"
    :label="`${(edit)?'Editar ':'Nueva'} Sucursal`"
    >
      <b-form-group
      label-cols-lg="3"


      label-for="nombre-sucu"
      label-align-sm="right"
      label="Nombre:"
      >
      <b-form-input id="nombre-sucu" class="bg-dark" :readonly="passchange"  v-model="sucuSelected.nombreSucu"></b-form-input>
      </b-form-group>
      <b-form-group
      label-cols-lg="3"


      label-for="username-sucu"
      label-align-sm="right"
      label="Acceso usuario:"
      >
      <b-form-input id="username-sucu" class="bg-dark" :readonly="passchange" v-model="sucuSelected.usernameSucu"></b-form-input>
      </b-form-group>
      <b-form-group
      label-cols-lg="3"
      v-show="!edit||passchange"

      label-for="pass-sucu"
      label-align-sm="right"
      label="Contraseña:"
      >
      <b-form-input type="password" class="bg-dark" id="pass-sucu" v-model="sucuSelected.passSucu"></b-form-input>
      </b-form-group>
      <b-form-group
      v-show="passchange"
      label-cols-lg="3"
      label-for="rpass-sucu"
      label-align-sm="right"
      label="Nueva Contraseña:"
      >
      <b-form-input type="password" class="bg-dark" id="rpass-sucu" v-model="sucuSelected.repPassSucu"></b-form-input>
      </b-form-group>


    </b-form-group>
    <b-form-group
     v-show="!passchange"
     label-size="lg"
     label-class="font-weight-bold pt-0"
     class="mb-0"
    label="Permisos y programacion"
    >
    <b-form-group
      label-cols-lg="3"
      label-align-sm="right"
      label="Programacion musical:"
      >
      <b-form-select class="bg-dark" v-model="sucuSelected.progRadio" :options="progRadios">
        <template #first>
          <b-form-select-option value="-1" disabled>Seleccione una programaci&oacute;n</b-form-select-option>
        </template>
      </b-form-select>
      </b-form-group>
    <b-form-group
      label-cols-lg="3"
      label-align-sm="right"
      label="Modo de reprod. Spot:"
      >
      <b-form-radio-group
                    v-model="sucuSelected.modoReprod"
                    :options="modoReprod"
                    value-field="item"
                    text-field="name"
                    :state="state"
                    disabled-field="notEnabled"
                  ></b-form-radio-group>
      </b-form-group>
    <b-form-group
      label-cols-lg="3"
      label-align-sm="right"
      label="Programar spot:"
      >
      <b-form-radio-group
                    v-model="sucuSelected.programSpot"
                    :options="[{name:'SI, programar',item:1},{name:'NO, programar',item:0}]"
                    value-field="item"
                    text-field="name"
                    :state="state"
                    disabled-field="notEnabled"
                  ></b-form-radio-group>
      </b-form-group>
      </b-form-group>
      <b-form-group
     label-cols-lg="3"
      label-align-sm="right"
      >
      <b-button @click="guardar()" class="m-1">Guardar</b-button>
      <b-button @click="btnCancelar()" class="m-1">Cancelar</b-button>
      </b-form-group>
  </b-col>
</b-row>

</b-card>


</template>
<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../components/NotificationPlugin/NotificationTemplate'
import SucursalServices from '../services/SucursalServices'
import ClienteServices from '../services/ClienteServices'
import ClienteProgramacion from '../services/ClienteProgramacionServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import moment from 'moment'
import UserService from '../services/UserServices'
import callHub from '../callHub'

import controlAireService from '@/services/ControlAireServices'


// const i18n = $t(modulo_sucursales.principal);
// console.log(i18n);

export default {
  components: {
    VueBootstrap4Table,
    ScaleLoader,
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_sucursales.principal')
    const colnames = i18labels.tabla.columnas
    return {
      name: 'TablaSucursales',
      edit:false,
      formAbm:false,
      passchange:false,
      sucuSelected:{
          idSucursal: -1,
          nombreSucu: '',
          usernameSucu:'',
          passSucu:'',
          progRadio:-1,
          progSpot:-1,
          programSpot:0
        },
        modoReprod: [
        { name: 'Radio', item: 1 }, { name: 'No radio', item: 0 }
      ],
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notificacion: { mensaje: '', tipo: 'error' },
      ruta: '',
      urlReprod: {},
      config: {
        show_refresh_button: false,
        show_reset_button: false,
        pagination: true,
        per_page: 10,
        pagination_info: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      columns:[
        {key:'clisuc_nombre',label:'Nombre de sucursal', sortable: true, sortDirection: 'desc'},
        {key:'cli_usuari',label:'Pertenece a', sortable: true, sortDirection: 'desc'},
        {key:'username',label:'Usuario', sortable: true, sortDirection: 'desc'},
        {key:'reproduced',label:'Estado', sortable: true, sortDirection: 'desc'},
        {key:'clisuc_fechaAlta',label:'Alta de sucursal', sortable: true, sortDirection: 'desc',
        formatter: (value,key,item)=>{
          const date = value.split('T')
          const fecha = moment(date[0],"YYYY-MM-DD").format("DD-MM-YY")
          return fecha
        }
      },
      {key:'actions',label:''},
      ],
      filterSucu:'',
      timer: null,
      isLoading: false,
      sucursales: [],
      sucursaleshub: [],
      avisarLimiteSuc: false,
      alcanzoLimiteSuc: false,
      alertCantMensaje: {
        mensaje: '',
        tipo: ''
      },
      user: null
    }
  },
  created () {
    this.i18n = this.$i18n
    ClienteProgramacion.listarProgRadios()
    ClienteProgramacion.listarProgSpot()
    this.cliSucursalesGetByCli()
    this.getCurrentUser()
    this.verificaPrefijo()
    },

  mounted () {
    this.cliSucursalesGetByCli()
    callHub.client.start()
    let self = this
    let stateClients = {}
    callHub.client.on('Receive', (response) => {
      let data = JSON.parse(response)
      let data2 = JSON.parse(data.send)
      let data3 = JSON.parse(data2.msg)
      let result = {
        User:data.User,
        Eject:data.Eject,
        action:data2.action,
        ...data3
      }
      if(localStorage.stateClients){
        stateClients = JSON.parse(localStorage.stateClients)
        stateClients[result.User.toLowerCase()] = result
        localStorage.stateClients = JSON.stringify(stateClients)
      }else{
        stateClients[result.User.toLowerCase()] = result
        localStorage.stateClients = JSON.stringify(stateClients)
      }
      self.verifConect()

     })


  },
  // cuando se cambia el componente, se limpia el timer
  destroyed () {
    clearInterval(this.timer)
  },
  computed:{
    conectadas(){
      return this.sucursales.filter(sucu=>sucu.conected>0).length
    },
    rows(){
      return this.sucursales.map((sucu)=>sucu)
    },
    progRadios(){
      return JSON.parse(localStorage.listProgRadio).map((radio)=>{
        return {
          value:radio.clipro_codigo,
          text:radio.clipro_nombre
        }
      })
    },
    progSpot(){
      return JSON.parse(localStorage.listProgSpot).map((spot)=>{
        return {
          value:spot.clipro_codigo,
          text:spot.clipro_nombre
        }
      })
    }

  },
  watch:{
      'sucuSelected.idSucursal': function(newval){
        if(newval==-1){
          this.sucuSelected.nombreSucu = ''
          this.sucuSelected.usernameSucu = ''
          this.sucuSelected.passSucu = ''
        }
      }
  },
  methods: {
    verifConect(){

      this.sucursales.forEach((sucu)=>{
        const sucuState = JSON.parse(localStorage.stateClients)[sucu.username.toLowerCase()]
        if(sucuState!=undefined){
          sucu.conectado = 1
          sucu._rowVariant='success'
          sucu.reproduced= sucuState.action
        }

      })
    },
    getCurrentUser () {
      const clienteLogueado = JSON.parse(UserService.current().Cliente)
      this.user = clienteLogueado
    },

    guardar(){
        SucursalServices.altaSucursal(this.sucuSelected)
      },

    cliSucursalesGetByCli () {
      this.isLoading = true

       SucursalServices.getcliSucursalByCliente().then((res) => {
         this.sucursales = res
         this.verifConect()
       })
      this.isLoading = false
    },
    escuchar (url) {
      const audio = new Audio(url)
      audio.play()
    },
    verificaPrefijo () {
      ClienteServices.getClienteByUsername().then(response => {
        /* Si tiene prefijo definido hago el ruteo hacia el componente Datos de la sucursal
        * Caso contrario el ruteo se hace con la page
        * Aclaración.. el ruteo lo hago por medio del nombre de la página para poder enviar props. Si lo hago
        * por medio del path, no se puede enviar props
        */
        if (response.cli_prefijo != null) {
          this.ruta = 'Datos de la sucursal'
        } else {
          this.ruta = 'Creá una nueva sucursal'
        }
      })
    },
    redirect (item, button) {
      this.$router.push(
        {
          name: this.ruta,
          params:
        {
          idSucursal: 0,
          nombreSucursal: '',
          sucUsuario: ''
        }
        })
    },
    async loginremoto (username,password) {
      UserService.login(username,password).then((response)=>{
        let {access_token} = response.data
        this.$refs["iframe-"+username].children[0].src=`${process.env.VUE_REPROD_BASE_URL}init/?token=${access_token}`
      })
    },
    btnCancelar(){
      this.formAbm=false
      this.passchange = false
      this.edit = false
    },
    btnPasswordChange(item){
      this.formAbm = true
      this.passchange= true
      this.sucuSelected.idSucursal = item.clisuc_codigo
      this.sucuSelected.nombreSucu = item.clisuc_nombre
      this.sucuSelected.usernameSucu = item.username
      this.sucuSelected.passSucu = item.password
    },
    btnAdd () {
      this.edit=false
      this.formAbm = true
      this.sucuSelected.idSucursal = -1
      if(this.progSpot.length==1){
        this.sucuSelected.progSpot=this.progSpot[0].value
      }
      this.sucuSelected.idSucursal = -1
      this.sucuSelected.nombreSucu = ''
      this.sucuSelected.usernameSucu = ''
      this.sucuSelected.passSucu = ''
      this.sucuSelected.programSpot = ''
      this.sucuSelected.modoReprod = null
      this.sucuSelected.progRadio = -1
      this.sucuSelected.progSpot = 33
    },
    btnEditar (item) {
      this.edit=true
      this.formAbm = true
      this.passchange = false
      this.sucuSelected.idSucursal = item.clisuc_codigo
      this.sucuSelected.nombreSucu = item.clisuc_nombre
      this.sucuSelected.usernameSucu = item.username
      this.sucuSelected.passSucu = item.password
      this.sucuSelected.progRadio = item.sucpgr_codigoProgramacionRadio
      this.sucuSelected.progSpot = item.sucpgr_codigoProgramacionSpot
      this.sucuSelected.programSpot = item.clisuc_permisoSpotPropio
      this.sucuSelected.modoReprod = item.clisuc_modoReprodSpotRadio

    },
    btnEliminar (item, button) {
      this.$confirm(
        {
          message: 'Confirmar para eliminar',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              this.isLoading = true
              SucursalServices.bajaSucursal(item.clisuc_codigo, item.username).then(res => {
                this.cliSucursalesGetByCli()
                this.isLoading = false
              })
            }
          }
        }
      )
    },
    redirectSucursalProgramacion (item, button) {
      this.$router.push(
        {
          name: 'Programaciones',
          params:
        {
          idSucursal: item.clisuc_codigo,
          nombreSucursal: item.clisuc_nombre,
          sucUsuario: item.username,
          permisoSpot: item.permisoSpot,
          modoReprod: item.modoReprod
        }
        })
    },
    actualizarEstadoSucursal () {
      this.timer = window.setInterval(() => {
        this.data.rows.forEach(row => {
          SucursalServices.estadoSucursal(row.username).then(response => {
            row.estado = response.estado
            row.tiempoDesconectado = this.tiempoDesconectado(response.diferencia)
          })
        })
      }, 600000)
    },
    tiempoDesconectado (tiempoDesconectado) {
      let ultimaConexion
      // calculo los días desde la ultima conexión
      const dias = Math.trunc(((tiempoDesconectado) / 60) / 24)

      var date = moment()

      if (tiempoDesconectado === -1) {
        ultimaConexion = 'Nunca se inició sesión'
      } else {
        if (dias === 0) {
          ultimaConexion = 'Última conexión: hoy a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias === 1) {
          ultimaConexion = 'Última conexión: Ayer a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias > 1) {
          ultimaConexion = 'Última conexión: ' + (date.subtract(tiempoDesconectado, 'minutes').format('DD/MM/YYYY HH:mm'))
        }
      }

      return ultimaConexion
    },
    chequearLimiteSucursales (cantSucursales) {
      // buscar con un service el limite de sucursales del cliente
      let limite
      ClienteServices.getClienteByUsername().then(res => {
        limite = res.cli_cantEquipos
        if (cantSucursales >= limite) {
          this.avisarLimiteSuc = true
          this.alcanzoLimiteSuc = true
          this.alertCantMensaje.mensaje = this.data.textos.alcanzoLimiteSucursales
          this.alertCantMensaje.tipo = 'default'
          this.$store.state.limiteSucursal = true
        } else {
          this.$store.state.limiteSucursal = false
        }
      })
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 500:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            case 403:
              this.notificacion.mensaje = 'Se ha denegado el acceso a esta consulta'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
              *luego, de la primer parte, con un substr para extraer lo que sigue de
              *"system.exception" (17 caracteres)
              */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  },

}
</script>
<style>
</style>
