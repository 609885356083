<template>
<form id="Login" class="box">
  <b-loading :is-full-page="$isLoadFullPage" v-model="loading" :can-cancel="true"></b-loading>
      <section>
          <b-field label="Usuario" label-position="on-border">
              <b-input v-model="usuario.name" type="text" required></b-input>
          </b-field>
          <b-field label="Contraseña" label-position="on-border">
              <b-input name="password" v-model="usuario.password" type="password" password-reveal  required></b-input>
          </b-field>
          <div>
            <b-button type="is-dark" @click="Login">Entrar</b-button>
          </div>
        </section>
</form>
</template>

<script>

import handleError from '../utils/handleErrors'
import { mapActions } from 'vuex'
import diaHabilService from '@/services/DiaHabilService'
import clienteHorarioService from '@/services/ClienteHorarioServices'
export default {
  layout: 'default',
  name: 'Login',
  components: {
  },
  data () {
    return {
      loading: false,
      usuario: {
        name: '',
        password: ''
      },
      notificacion: {
        mensaje: '',
        tipo: 'error'
      }
    }
  },
  methods: {
    ...mapActions({
      Auth: 'Auth'
    }),
    Login () {
      this.loading = true
      this.Auth(this.usuario).then((data) => {
        diaHabilService.get()
          .then(() => clienteHorarioService.get())
          .then(() => {
            location.reload()
            this.loading = false
          })
      })
        .catch(err => {
          console.log(err)
          this.notificacion.mensaje = handleError.errorApi(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.$isLoad = false
        })
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  }
}
</script>
