import ApiElevenLabs from './ApiElevenLabs'
import ApiElevenLabsGenVoz from './ApiElevenLabsGenVoz'
import axios from 'axios'
const RequestElevenLabsServices = {}

async function GetBlobAudio (endpoint) {
  const api = axios.create({
    baseURL: 'https://api.elevenlabs.io/v1/'
  })

  api.interceptors.request.use(
    (config) => {
      config.maxBodyLength = Infinity
      config.headers = {
        accept: 'audio/mpeg',
        'Content-Type': 'application/json',

        'xi-api-key': '57a827bc782b9525fa20bb64087a7454'
        //'xi-api-key': 'bf5b01b1fbb90229ec970889968b3a8e'
        // 'xi-api-key':'c502191eb5654eb272120cc5ecf6d55e' esta es la cuenta oficial clubfok
      }
      config.responseType = 'arraybuffer'
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  const { data } = await api.get(endpoint)
  const blob = new Blob([data], { type: 'audio/mpeg' })
  return blob
}

RequestElevenLabsServices.getHistoryAudio = async function (id) {
  const url = `history/${id}/audio`
  const data = await GetBlobAudio(url)
  return URL.createObjectURL(data)
}
RequestElevenLabsServices.getHistoryAudioLocal = function (id) {
  const data = JSON.parse(localStorage.HistoryList).find(
    // eslint-disable-next-line eqeqeq
    (h) => h.id_history == id
  )
  return data?.voz || ''
}
RequestElevenLabsServices.getHistoryCreate = async function (force = false) {
  return (localStorage.HistoryList && !force) ? JSON.parse(localStorage.HistoryList) : ApiElevenLabs.get('history')
    .then((voicesResponse) => {
      const {
        data: { history }
      } = voicesResponse
      const result = []
      history
        // eslint-disable-next-line eqeqeq
        //.filter((vo) => vo.voice_category == 'cloned')
        .forEach((voice) => {
          result.push({
            id: voice.date_unix,
            id_history: voice.history_item_id,
            locutor: voice.voice_name,
            id_locutor: voice.voice_id,
            texto: voice.text,
            voz: ''
          })
        })
      return result
    })
    .then(async (history) => {
      for (const ix in history) {
        const url = await this.getHistoryAudio(history[ix].id_history)
        history[ix].voz = url
      }

      return history
    })
    .then((result) => {
      localStorage.HistoryList = JSON.stringify(result)
    })
}
RequestElevenLabsServices.getInfoUser = async function () {
  return ApiElevenLabs.get('user/subscription').then((subscriptionResponse) => {
    return subscriptionResponse.data
  })
}
RequestElevenLabsServices.getVoicesCloned = async function () {
  return ApiElevenLabs.get('voices').then((voicesResponse) => {
    const {
      data: { voices }
    } = voicesResponse
    // eslint-disable-next-line eqeqeq
    const result = voices

    localStorage.VoiceList = JSON.stringify(result)
    return result
  })
}
RequestElevenLabsServices.GetHistory = (idLoc, text) => {
  return JSON.parse(localStorage.HistoryList).find(
    (hist) =>
      // eslint-disable-next-line eqeqeq
      hist.id_locutor == idLoc &&
      // eslint-disable-next-line eqeqeq
      hist.texto.toLowerCase().trim() == text.toLowerCase().trim()
  )
}
RequestElevenLabsServices.textToSpeech = async function (voice, ptext) {
  const text = ptext
  var url = ''
  //const history = this.GetHistory(voice, ptext)

  //if (history == null) {
    // const cliLogeado = JSON.parse(
    //   JSON.parse(localStorage.user).Cliente
    // ).cli_codigo
    const data = JSON.stringify({
      model_id: 'eleven_multilingual_v2',
      text: text,
      voice_settings: {
        similarity_boost: 1,
        stability: 1,
        style: 1,
        use_speaker_boost: true
      }
      // ,
      // cli_clubf5: `${cliLogeado}`
    })

    const result = await ApiElevenLabsGenVoz.post(
      `text-to-speech/${voice}/stream?optimize_streaming_latency=1&output_format=mp3_44100_96`,
      data
    )
  const blob = new Blob([result.data], { type: 'audio/mpeg' })
  url = URL.createObjectURL(blob)
  return [url,blob]
}
export default RequestElevenLabsServices
