import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

class CallHub {
  constructor () {
    this.client = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_BASE_URL}/data-hub`)
      .configureLogging(LogLevel.Information)
      .build()
  }

  start () {
    this.client.start()
      .then(()=>{console.log('Conectado')})
      .catch((error)=>{console.error(error)})
  }
}

export default new CallHub()
