<template>
  <div>
    <template>
        <b-card no-body  header-tag="h6" header-class="p-4">
          <b-dropdown id="dropdown-form" :text="`Seleccione un spots`" menu-class="w-100 bg-dark" ref="dropdown"
            class="m-2">
            <b-dropdown-form>
              <label class="sr-only" for="search-spots">&#x1f50d;</label>
              <b-input-group prepend="" class="mb-2 mr-sm-2 mb-sm-0">
                <b-input-group-prepend is-text>&#x1f50d;</b-input-group-prepend>
                <b-form-input v-model="search" id="search-spots" type="text"></b-form-input>
              </b-input-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button button-class="text-center text-black border" :class="classSpot.find(tsp=>tsp.value==spot.tipo).class" v-for="spot in availableOptions"
              :key="spot.codSpot" @click="onOptionClick(spot)"><strong>{{ spot.nombreSpot }}</strong>
            </b-dropdown-item-button>
            <b-dropdown-divider ></b-dropdown-divider>
          </b-dropdown>
        </b-card>
            <!-- <b-card-group columns>
            <b-card v-show="programacion.length>0" :class="classSpot.find(tsp=>tsp.value==prog.tipo).class" v-for="prog in programacion" class="text-white" :key="prog.codSpot" >
             <b-card-title class="text-center">{{ prog.nombreSpot }}</b-card-title>
             <b-card-title class="text-center">Hora de inicio: {{ prog.horadesde }}</b-card-title>
            </b-card>
            </b-card-group> -->
   </template>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "tag-spot",
  props: {

    options: {
      type: Array,
      description: "Ingrese opciones"
    },
    tipoSpot:{
      type: String,
      description:"Orden de aparicion"
    },
    horadesde: {
      type: String,
      description: "Hora de comienzo"
    }

  },
  data() {
    return {
      search: '',
      programacion:[],
      classSpot: [
        {
          value: 'inst',
          class: 'spots-azul'
        },
        {
          value: 'prom',
          class: 'spots-verde'
        },
        {
          value: 'noti',
          class: 'spots-marron'
        }
      ],
      limit: 5
    }
  },

  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const criteria = this.criteria
      // Filter out already selected options
      const isEqual = (hs1,hs2)=>{
        let momenths1 = moment(hs1,"HH:mm")
        let momenths2 = moment(hs2,"HH:mm")

        const diffhs = momenths1.diff(momenths2,'milliseconds')
        console.log(diffhs)
        return diffhs==0
      }

      const options = this.$props.options.filter(opt => this.programacion.find(val=>val.nombre==opt.nombreSpot&&isEqual(val.horadesde,this.$props.horadesde))==undefined)

      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.nombreSpot.toLowerCase().indexOf(criteria) > -1);
      }
      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    }
  },
  methods: {
    removeTagOption({ tag, removeTag }, pos) {
      removeTag(tag)
      this.$emit('remove-codspot', pos)
    },
    onOptionClick(option) {
      if(this.programacion.length>=5)return;
      const totalsec = this.programacion.map(spo=>spo.duracion).reduce((acum,currensec)=>acum+currensec,0)
      const horadesde = moment(this.$props.horadesde,"HH:mm:ss").add(totalsec,'seconds')
      this.programacion.push({
            ...option,
            horadesde:horadesde.format("HH:mm:ss"),
          })
      this.$emit('set-spot', option)
      this.$emit('update-codspot', this.programacion)
      this.search = ''
    },
    initParams(){

    }
  },
  mounted(){
    this.initParams()
  },
}
</script>
