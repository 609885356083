import api from './api'

const clienteProgramacionService = {}

clienteProgramacionService.listarProgRadios = async function () {
  return api.get('/clienteProgramacion/Radio/').then(res => localStorage.listProgRadio=JSON.stringify(res.data))
}

clienteProgramacionService.listarProgSpot = async function () {
  return api.get('/clienteProgramacion/Spot/').then(res => localStorage.listProgSpot=JSON.stringify(res.data))
}

export default clienteProgramacionService
