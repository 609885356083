<!-- eslint-disable vue/no-parsing-error -->
<template>
<div class="section is-dark">
    <nav class="level is-dark">
      <p class="level-item">
        <div class="buttons">
    </div>
      </p>
      <p class="level-item">
        <div class="buttons">
          <b-button
        label="Nuevo"
        type="is-success"
        icon-left="pen-plus"
        @click="modalAltaSucu=true"
      />
      <b-button
                label="Cambiar contraseña"
                type="is-dark"
                icon-left="key"
                v-on:click="modalPasswordSucu=true"
                v-show="sucuSelected.length==1"
                />
          <b-button
              label="Editar"
              type="is-white"
              icon-left="pencil"
              @click="btnEditar(0)"
              v-show="sucuSelected.length==1"
              />
          <b-button
              label="Eliminar"
              type="is-danger"
              icon-left="delete"
              @click="btnEliminar"
              v-show="sucuSelected.length>0"
              />
        </div>
      </p>
    </nav>
    <b-table
          class="is-dark"
          :sticky-header="true"
          checkable
          cellClass="is-sticky-column-one"
          checkbox-type="is-info"
          :checked-rows.sync="sucuSelected"
          :data="rows"
          :row-class="(row, index) => `is-${row.event}`"
          :paginated="true"
          per-page="600"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          >
          <b-table-column :key="column.field" :width="column.width" v-for="column in columns" :sortable="column.searchable" :searchable="column.searchable" :field="column.field" :label="column.label" v-slot="props">
            {{ props.row[column.field] }}
          </b-table-column>
    </b-table>
    <b-modal v-model="modalAltaSucu">
      <section class="box">
        <b-field label="Nombre" label-position="on-border">
          <b-input v-model="datosSucursal.nombreSucursal"></b-input>
        </b-field>
        <b-field label="Usuario" label-position="on-border">
          <b-input v-model="datosSucursal.nombreUsuarioSucursal"></b-input>
        </b-field>
        <b-field label="Contraseña" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia1" password-reveal></b-input>
        </b-field>
        <b-field label="Re-Contraseña" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia2" password-reveal></b-input>
        </b-field>
        <b-field label="Programación musica">
          <div class="block">
            <b-radio v-for="programacion in optionsRadio" :key="programacion.item" :native-value="programacion.item" name="progMusic" v-model="datosSucursal.radioSelected">
              {{ programacion.name }}
            </b-radio>
          </div>
        </b-field>
        <b-field label="Programación spot">
          <div class="block">
            <b-radio v-for="spot in optionsSpot" :key="spot.item" :native-value="spot.item" name="progSpot" v-model="datosSucursal.spotSelected">
              {{ spot.name }}
            </b-radio>
          </div>
        </b-field>
        <b-field label="Premisos para subir y programar spot">
          <div class="block">
            <b-radio :native-value="0" name="permSpot" v-model="datosSucursal.permisoSpots">
              No, subir y programar
            </b-radio>
            <b-radio :native-value="1" name="permSpot" v-model="datosSucursal.permisoSpots">
              S&iacute;, subir y programar
            </b-radio>
          </div>
        </b-field>
        <b-button
                label="Guardar"
                type="is-danger"
                icon-left="check"
                v-on:click="guardarSucursal"/>
      </section>
    </b-modal>
    <b-modal v-model="modalPasswordSucu">
      <section class="box">
        <b-field label="Usuario" readonly="true" label-position="on-border">
          <b-input v-model="datosSucursal.nombreUsuarioSucursal"></b-input>
        </b-field>
        <b-field label="Contraseña Actual" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia1" password-reveal></b-input>
        </b-field>
        <b-field label="Contraseña Nueva" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contraseniaNew" password-reveal></b-input>
        </b-field>
        <b-field label="Re-Contraseña" label-position="on-border">
          <b-input type="password" v-model="datosSucursal.contrasenia2" password-reveal></b-input>
        </b-field>
        <b-button
                label="Guardar"
                type="is-danger"
                icon-left="check"
                v-on:click="guardarPassword"/>
      </section>
    </b-modal>
  </div>
</template>
<script>
import NotificationTemplate from '../components/NotificationPlugin/NotificationTemplate'
import SucursalServices from '../services/SucursalServices'
import ClienteServices from '../services/ClienteServices'
import ClienteProgramacion from '../services/ClienteProgramacionServices'
import moment from 'moment'
import UserService from '../services/UserServices'
import callHub from '../callHub'
// const i18n = $t(modulo_sucursales.principal);
// console.log(i18n);

export default {
  data () {
    return {
      name: 'TablaSucursales',
      edit: false,
      formAbm: false,
      passchange: false,
      modalAltaSucu: false,
      modalPasswordSucu:false,
      hubData: {},
      sucuSelected: [],
      modoReprod: [
        { name: 'Radio', item: 1 }, { name: 'No radio', item: 0 }
      ],
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      datosSucursal: {
        idSucursal: -1,
        nombreSucursal: '',
        nombreUsuarioSucursal: '',
        clientePrefijo: '',
        contrasenia1: '',
        contrasenia2: '',
        radioSelected: 0,
        spotSelected: 0,
        permisoSpots: 0,
        modoReprod:0
      },
      notificacion: { mensaje: '', tipo: 'error' },
      ruta: '',
      urlReprod: {},
      config: {
        show_refresh_button: false,
        show_reset_button: false,
        pagination: true,
        per_page: 10,
        pagination_info: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      columns: [
        {
          field: 'clisuc_nombre',
          label: 'Reproductor',
          width: '100',
          searchable: true
        },
        {
          field: 'username',
          label: 'Usuario',
          width: '100',
          searchable: true
        },
        {
          field: 'detalle',
          label: 'Eventos',
          width: '100',
          searchable: false
        }
      ],
      filterSucu: '',
      timer: null,
      isLoading: false,
      sucursales: [],
      sucursaleshub: [],
      avisarLimiteSuc: false,
      alcanzoLimiteSuc: false,
      alertCantMensaje: {
        mensaje: '',
        tipo: ''
      },
      user: null
    }
  },
  created () {
    this.i18n = this.$i18n
    // this.cliSucursalesGetByCli()
    this.getCurrentUser()
    this.verificaPrefijo()
  },

  mounted () {
    this.cliSucursalesGetByCli()
    this.$updateView(callHub, 'hubData')
    this.listarProgramacionesRadio()
    this.listarProgramacionesSpot()
  },
  // cuando se cambia el componente, se limpia el timer
  destroyed () {
    clearInterval(this.timer)
  },
  computed: {
    conectadas () {
      return this.sucursales.filter(sucu => sucu.conected > 0)
    },
    rows () {
      return this.sucursales.map((sucu) => sucu).sort((a,b)=>{
        if(a.conected<b.conected) return 1;
        if(a.conected>b.conected) return -1;
      })
    },
    progRadios () {
      return JSON.parse(localStorage.listProgRadio).map((radio) => {
        return {
          value: radio.clipro_codigo,
          text: radio.clipro_nombre
        }
      })
    },
    progSpot () {
      return JSON.parse(localStorage.listProgSpot).map((spot) => {
        return {
          value: spot.clipro_codigo,
          text: spot.clipro_nombre
        }
      })
    }

  },
  watch: {
    hubData (newval) {
     
      // eslint-disable-next-line no-unused-expressions, eqeqeq
      const sucuConectados = this.sucursales.map((sucu) => {
        if (sucu.username.toLowerCase() == newval.User.toLowerCase()) {
          sucu.detalle = newval.Send
          sucu.event = newval.Action
          sucu.conected = (newval.Action=='desconectado')?0:1
          return sucu
        } else {
          return sucu
        }
      })
      this.sucursales = sucuConectados
    },
    'sucuSelected.idSucursal': function (newval) {
      if (newval == -1) {
        this.sucuSelected.nombreSucu = ''
        this.sucuSelected.usernameSucu = ''
        this.sucuSelected.passSucu = ''
      }
    }
  },
  methods: {
    // Funciones para nueva sucursal
    editarSucursal () {
      // Obtengo por parametro el codigo de la sucursal para editar, 0 si es para crear una nueva
      if (this.nombreSucursal.length > 0 && this.idSucursal != 0 && this.contrasenia1.length > 0) {
        if (this.contrasenia1 === this.contrasenia2) {
          this.isLoading = true
          SucursalServices.put(this.idSucursal, this.nombreSucursal, this.nombreUsuarioSucursal, this.contrasenia1)
            .then(res => {
              // si todo sale ok, redirijo a sucursales
              this.isLoading = false
              this.$router.push({ path: '/sucursales' })
            })
            .catch(err => {
              this.handleError(err)
              this.notifyVue('top', 'center', this.notificacion.mensaje)
              this.isLoading = false
            })
        } else {
          this.notificacion.mensaje = 'Las contraseñas no coinciden'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        }
      } else {
        this.notificacion.mensaje = 'Debe completar todos los campos'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },
    guardarSucursal () {
      if (this.datosSucursal.radioSelected != 0 && this.datosSucursal.spotSelected != 0) {
        if (this.datosSucursal.nombreSucursal.length > 0 && this.datosSucursal.nombreUsuarioSucursal.length > 0 && this.datosSucursal.contrasenia1.length > 0) {
          if (this.datosSucursal.contrasenia1 === this.datosSucursal.contrasenia2) {
            this.isLoading = true
            SucursalServices.altaSucursal(this.datosSucursal)
              .then(res => {
                // si todo sale ok, redirijo a sucursales
                this.isLoading = false
                this.$router.push({ path: '/sucursales' })
              })
              .catch(err => {
                this.handleError(err)
                this.notifyVue('top', 'center', this.notificacion.mensaje)
                this.isLoading = false
              })
          } else {
            this.notificacion.mensaje = 'Las contraseñas no coinciden'
            this.notifyVue('top', 'center', this.notificacion.mensaje)
          }
        } else {
          this.notificacion.mensaje = 'Debe completar todos los campos'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.pasoAnterior()// vuelvo al paso anterior del wizard
        }
      } else {
        this.notificacion.mensaje = 'Debe seleccionar las programaciones'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },
    listarProgramacionesRadio () {
      const resJson = []
      ClienteProgramacion.listarProgRadios().then(res => {
        const data = JSON.parse(localStorage.listProgRadio)
        data.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsRadio = resJson
        this.cantProgRadio = this.optionsRadio.length
        this.isLoading = false
      })
    },
    listarProgramacionesSpot () {
      const resJson = []
      ClienteProgramacion.listarProgSpot().then(res => {
        const data = JSON.parse(localStorage.listProgSpot)
        data.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsSpot = resJson
        this.cantProgSpot = this.optionsSpot.length
        this.isLoading = false
      })
    },
    // fin
    getCurrentUser () {
      const clienteLogueado = JSON.parse(UserService.current().Cliente)
      this.user = clienteLogueado
    },

    guardar () {
      if (this.edit) {
        SucursalServices.put(this.sucuSelected)
      } else {
        SucursalServices.altaSucursal(this.sucuSelected).then(result => {
          this.notifyVue('top', 'center', (result) ? 'Registro exitoso' : result, this.notifIconOk, (result) ? 1 : 2)
          this.cliSucursalesGetByCli()
        })
      }
    },

    cliSucursalesGetByCli () {
      this.isLoading = true

      SucursalServices.getcliSucursalByCliente().then((res) => {
        this.sucursales = res
      })
      this.isLoading = false
    },
    escuchar (url) {
      const audio = new Audio(url)
      audio.play()
    },
    verificaPrefijo () {
      ClienteServices.getClienteByUsername().then(response => {
        /* Si tiene prefijo definido hago el ruteo hacia el componente Datos de la sucursal
        * Caso contrario el ruteo se hace con la page
        * Aclaración.. el ruteo lo hago por medio del nombre de la página para poder enviar props. Si lo hago
        * por medio del path, no se puede enviar props
        */
        if (response.cli_prefijo != null) {
          this.ruta = 'Datos de la sucursal'
        } else {
          this.ruta = 'Creá una nueva sucursal'
        }
      })
    },
    redirect () {
      this.$router.push(
        {
          name: this.ruta,
          params:
        {
          idSucursal: 0,
          nombreSucursal: '',
          sucUsuario: ''
        }
        })
    },
    async loginremoto (username, password) {
      UserService.login(username, password).then((response) => {
        const { access_token } = response.data
        this.$refs['iframe-' + username].children[0].src = `${process.env.VUE_REPROD_BASE_URL}init/?token=${access_token}`
      })
    },
    btnCancelar () {
      this.formAbm = false
      this.passchange = false
      this.edit = false
    },
    btnPasswordChange () {
      const item = this.sucuSelected[0]
      this.datosSucursal.idSucursal = item.clisuc_codigo
      this.datosSucursal.nombreUsuarioSucursal = item.username
    },
    btnAddCliente () {
      this.formAbm = true
      this.sucuSelected.iscliente = 1
      this.sucuSelected.idSucursal = -1
      this.sucuSelected.nombreSucu = ''
      this.sucuSelected.usernameSucu = ''
      this.sucuSelected.passSucu = ''
    },
    btnEditPassword () {
      this.sucuSelected.idSucursal = -1
      this.sucuSelected.nombreSucu = ''
      this.sucuSelected.usernameSucu = ''
      this.sucuSelected.passSucu = ''
      this.sucuSelected.programSpot = ''
      this.sucuSelected.modoReprod = null
      this.sucuSelected.progRadio = -1
      this.sucuSelected.progSpot = 33
    },
    btnEditar (index = 0) {
      this.edit = true
      this.passchange = false
      const item = this.sucuSelected[index]
      this.datosSucursal.idSucursal = item.clisuc_codigo
      this.datosSucursal.nombreSucursal = item.clisuc_nombre
      this.datosSucursal.nombreUsuarioSucursal = item.username
      this.datosSucursal.radioSelected = item.sucpgr_codigoProgramacionRadio
      this.datosSucursal.spotSelected = item.sucpgr_codigoProgramacionSpot
      this.datosSucursal.permisoSpots = item.clisuc_permisoSpotPropio
      this.datosSucursal.modoReprod = item.clisuc_modoReprodSpotRadio
      this.modalAltaSucu = true
    },
    btnEliminar () {
      if (confirm('¿Esta seguro de eliminar la sucursal?')) {
        SucursalServices.bajaSucursal(this.sucuSelected).then(() => {
          this.isLoading = true
          this.cliSucursalesGetByCli()
          this.isLoading = false
        })
      }
    },
    redirectSucursalProgramacion (item) {
      this.$router.push(
        {
          name: 'Programaciones',
          params:
        {
          idSucursal: item.clisuc_codigo,
          nombreSucursal: item.clisuc_nombre,
          sucUsuario: item.username,
          permisoSpot: item.permisoSpot,
          modoReprod: item.modoReprod
        }
        })
    },
    actualizarEstadoSucursal () {
      this.timer = window.setInterval(() => {
        this.data.rows.forEach(row => {
          SucursalServices.estadoSucursal(row.username).then(response => {
            row.estado = response.estado
            row.tiempoDesconectado = this.tiempoDesconectado(response.diferencia)
          })
        })
      }, 600000)
    },
    tiempoDesconectado (tiempoDesconectado) {
      let ultimaConexion
      // calculo los días desde la ultima conexión
      const dias = Math.trunc(((tiempoDesconectado) / 60) / 24)

      var date = moment()

      if (tiempoDesconectado === -1) {
        ultimaConexion = 'Nunca se inició sesión'
      } else {
        if (dias === 0) {
          ultimaConexion = 'Última conexión: hoy a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias === 1) {
          ultimaConexion = 'Última conexión: Ayer a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias > 1) {
          ultimaConexion = 'Última conexión: ' + (date.subtract(tiempoDesconectado, 'minutes').format('DD/MM/YYYY HH:mm'))
        }
      }

      return ultimaConexion
    },
    chequearLimiteSucursales (cantSucursales) {
      // buscar con un service el limite de sucursales del cliente
      let limite
      ClienteServices.getClienteByUsername().then(res => {
        limite = res.cli_cantEquipos
        if (cantSucursales >= limite) {
          this.avisarLimiteSuc = true
          this.alcanzoLimiteSuc = true
          this.alertCantMensaje.mensaje = this.data.textos.alcanzoLimiteSucursales
          this.alertCantMensaje.tipo = 'default'
          this.$store.state.limiteSucursal = true
        } else {
          this.$store.state.limiteSucursal = false
        }
      })
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 500:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            case 403:
              this.notificacion.mensaje = 'Se ha denegado el acceso a esta consulta'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
              *luego, de la primer parte, con un substr para extraer lo que sigue de
              *"system.exception" (17 caracteres)
              */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  }

}
</script>
<style>
tr.is-conectado{
  background-color: green;
  color: white;
}
tr.is-desconectado{
  background-color: rgb(54, 54, 54);
  color: white;
}
tr.is-error{
  background-color: red;
  color: white;
}

</style>
