<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <card>
          <div class="row">

            <div class="col-lg-4">
              <base-input label="Tipo de empresa">
                <b-form-select v-model="filtroSelected.tipoEmpresa" @change="selectFiltro">
                    <option v-for="filtro in filtros.tipoEmpresa" v-bind:value="filtro.cod" >
                      {{ filtro.nombre }}
                    </option>
                </b-form-select>
              </base-input>
            </div>

            <div class="col-lg-4">
              <base-input label="Estilo de empresa">
                <b-form-select v-model="filtroSelected.estiloEmpresa"  @change="selectFiltro" :disabled="filtroSelected.tipoEmpresa === 0">
                    <option v-for="filtro in filtros.estiloEmpresa" v-bind:value="filtro.cod" :key="filtro.cod">
                      {{ filtro.nombre }}
                    </option>
                </b-form-select>
              </base-input>
            </div>

            <div class="col-lg-4">
              <base-input label="Ritmo">
                <b-form-select v-model="filtroSelected.ritmo"  @change="selectFiltro">
                    <option v-for="filtro in filtros.ritmo" v-bind:value="filtro.cod" :key="filtro.cod">
                      {{ filtro.nombre }}
                    </option>
                </b-form-select>
              </base-input>
            </div>

          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="collapse-panel">
          <div id="accordion" class="accordion circulos-playlist" role="tablist">
            <div v-for="genero in generosMusicales" :key="genero.index">
              <b-card no-body class="mb-0">
                <div class="panel-title">
                  <h4>
                    <a v-b-toggle:collapse :href="'#collapse-'+(genero.index)" v-on:click="filtroSubgenero('Todos')" @click.prevent>{{genero.genero}}</a>
                  </h4>
                </div>

                <div class="row">
                  <b-collapse :id="'collapse-'+ (genero.index)" accordion="my-accordion" role="tabpanel" v-bind:visible="genero.index === 0">
                    <!--etiquetas filtro-->
                    <b-card-header>
                      <div class="subgen">
                        <a :href="''" @click.prevent class="label-subgen" v-on:click="filtroSubgenero('Todos')">Todos</a>
                      </div>
                      <div class="subgen" v-for="subGenero in subGeneros" v-if="subGenero.genero === genero.genero">
                        <a :href="''" @click.prevent class="label-subgen" v-on:click="filtroSubgenero(subGenero)">{{subGenero.subGenero}}</a>
                      </div>
                      <div style="clear: both"></div>
                    </b-card-header>

                    <!--cuerpo circulos-->
                    <b-card-body>
                      <div class="row">
                        <div class="col-xs" v-for="radio in radios" v-if="genero.genero === radio.genero">
                          <div class="dragg-container" v-if="radio.subGenero === subGeneroSelected">
                            <div class="radio-dragg" :data-event='radio.data' :data-cod='radio.cod' :data-id='radio.codRadio' :data-img='radio.img' :data-stream='radio.enlanceStream'>
                              <!-- <img :id="'popover-' + radio.codRadio" :src="'/datos/fotos/' + (radio.img)" class="rounded-circle" :title="radio.nombre"/> -->
                                <div class="card bg-info text-white rounded-circle" style="cursor: move;">
                                  <span class="card-body p-2 ">{{radio.nombre}}</span>
                                </div>

                              <!-- <b-popover :target="'popover-' + radio.codRadio" triggers="hover" placement="bottom">
                                Escuchar demo
                                <button
                                    id="button-popover"
                                    type="button"
                                    class="btn el-tooltip btn-icon btn-fab btn-success border border-info btn-sm"
                                    aria-describedby="el-tooltip-8889"
                                    :title="'Play demo'"
                                    @click="reproducirDemo(radio)"
                                    >
                                    <small><b-icon icon="play-fill" :data-idRad="radio.codRadio"></b-icon></small>
                                </button>
                              </b-popover> -->
                            </div>
                          </div>
                          <div class="dragg-container" v-if="subGeneroSelected === null">
                            <div class="radio-dragg" :data-event='radio.data' :data-cod='radio.cod' :data-id='radio.codRadio' :data-img='radio.img' :data-stream='radio.enlanceStream'>
                              <!-- <img :id="'popover-' + radio.codRadio" :src="'/datos/fotos/' + (radio.img)" class="rounded-circle" :title="radio.nombre"/> -->
                             <div class="card bg-info text-white rounded-circle" style="cursor: move;">
                                  <span class="card-body p-2" >{{radio.nombre}}</span>
                                </div>
                              <!-- <b-popover :target="'popover-' + radio.codRadio" triggers="hover" placement="bottom">
                                Escuchar demo
                                <button
                                    id="button-popover"
                                    type="button"
                                    class="btn el-tooltip btn-icon btn-fab btn-success border border-info btn-sm"
                                    aria-describedby="el-tooltip-8889"
                                    :title="'Play demo'"
                                    @click="reproducirDemo(radio)"
                                    >
                                    <b-icon icon="play-fill" :data-idRad="radio.codRadio"></b-icon>
                                </button>
                              </b-popover> -->

                            </div>
                          </div>

                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!--Confirm dialog-->
        <template>
          <vue-confirm-dialog></vue-confirm-dialog>
        </template>
      </div>

      <div class="col-lg-9">
        <card :title="titulo">
          <FullCalendar
            :options="calendarOptions"
            id="mycalendar"
            :config="calendarOptions.config"
          />
        </card>
      </div>
    </div>
    <b-overlay
      :show="isLoading"
      opacity="0.4"
      no-wrap
      >
      <template #overlay>
        <div class="text-center">
          <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
        </div>
      </template>
    </b-overlay>

  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import ListPlugin from '@fullcalendar/list'
import InteractionPlugin, { Draggable } from '@fullcalendar/interaction'
import cliproRadioServices from '../../services/ClienteProgramacionRadioServices'
import generoMusicalServices from '../../services/GeneroMusicalServices'
import generoMusicalSubServices from '../../services/GeneroMusicalSubServices'
import filtros from '../../services/FiltroServices'
import moment from 'moment'
import Vue from 'vue'
import clienteProgramacionRadioService from '../../services/ClienteProgramacionRadioServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import DiasServices from '../../services/DiaHabilService'
import CliHorarioServices from '../../services/ClienteHorarioServices'
import { BaseAlert } from '@/components'

export default {
  components: {
    FullCalendar,
    ScaleLoader,
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_radios.principal')
    const colnames = i18labels.tabla_programaciones.columnas

    return {
      codigoProgramacion: this.$route.params.codigoProgramacion,
      nombreProgramacion: this.$route.params.nombreProgramacion,
      titulo: '',
      type: ['', 'danger'],
      notifications: {
        topCenter: false
      },
      notificacion: '',
      calendarOptions: {
        plugins: [DayGridPlugin, InteractionPlugin, TimeGridPlugin, ListPlugin],
        initialView: 'timeGridWeek',
        events: [],
        headerToolbar: {
          left: '',
          center: '',
          right: ''
        },
        slotMaxTime:'23:30:00',
        editable: true,
        selectable: true,
        dayMaxEvents: false,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.eliminar,
        eventsSet: this.handleEvents,
        locale: 'es',
        droppable: true,
        allDaySlot: false,
        eventOverlap: false,
        dropAccept: true,
        eventResize: this.resize,
        eventDrop: this.mover,
        dayHeaderFormat: { weekday: 'short' },
        eventReceive: this.guardar,
        businessHours: {
          daysOfWeek: [],
          startTime: '',
          endTime: ''
        }
      },
      generosMusicales: [],
      subGeneros: [],
      radios: [],
      subGeneroSelected: null,
      filtros: {
        tipoEmpresa: [],
        estiloEmpresa: [],
        ritmo: []
      },
      filtroSelected: {
        tipoEmpresa: 0,
        estiloEmpresa: 0,
        ritmo: 0
      },
      isLoading: false
    }
  },
  created () {
    this.verificarParametros()
    this.getSubGenerosMusicales()
    this.cargarDatosFiltros()
    this.getGenerosMusicales()
    this.getDiasHabilesCliente()
    this.getHorarioCliente()
  },
  mounted () {
    this.getPlaylists()
    this.buildDraggables()
    this.getProgramaciones()
  },
  methods: {


    getDiasHabilesCliente () {
      const dias = []
      this.isLoading = true
      DiasServices.get().then(res => {
        res.forEach(element => {
          dias.push(element.cliDha_codigoDia)
        })
        this.calendarOptions.businessHours.daysOfWeek = dias
        this.isLoading = false
      })
    },
    getHorarioCliente () {
      // traer los días que tiene seleccionado el cliente
      this.isLoading = true
      CliHorarioServices.get().then(res => {

        this.calendarOptions.businessHours.startTime = res.cliHor_horaDesde
        this.calendarOptions.businessHours.endTime = res.cliHor_horaHasta
        this.isLoading = false
      })
    },
    buildDraggables () {
      const draggableEl = InteractionPlugin.Draggable
      const containerEl = document.getElementById('accordion')

      new Draggable(containerEl, {
        itemSelector: '.radio-dragg',
        eventData: function (eventEl) {
          var aux = eventEl.dataset.event
          var ret = JSON.parse(aux)
          ret.duration = '02:00'

          ret.extendedProps = {
            cod: parseInt(eventEl.dataset.cod),
            codRadio: eventEl.dataset.id,
            radImagen: eventEl.dataset.img
          }
          return ret
        }
      })
    },
    filtroSubgenero (etiqueta) {
      if (etiqueta === 'Todos') {
        this.subGeneroSelected = null
      } else {
        this.subGeneroSelected = etiqueta.subGenero
      }
    },
    verificarParametros () {
      if (this.codigoProgramacion === undefined || this.nombreProgramacion === undefined) {
        this.$router.push({
          name: 'Mi música'
        })
      } else {
        this.titulo = this.nombreProgramacion
      }
    },
    cargarDatosFiltros () {
      const tipEmpJson = [{ nombre: 'Todas las empresas', cod: 0 }]
      const tipEmpGrJson = []
      const ritmoJson = [{ nombre: 'Todos los ritmos', cod: 0 }]
      const estiloJson = [{ nombre: 'Todos los estilos', cod: 0 }]

      filtros.listarTipoEmpresa().then(res => {
        res.forEach(element => {
          tipEmpJson.push({
            nombre: element.tipEmp_nombre,
            cod: element.tipEmp_codigo
          })
        })
        this.filtros.tipoEmpresa = tipEmpJson
      })

      filtros.listarTipoEmpresaGrupo().then(res => {

      })

      filtros.listarRitmo().then(res => {
        res.forEach(element => {
          ritmoJson.push({
            nombre: element.ritmos_nombre,
            cod: element.ritmos_codigo
          })
        })
        this.filtros.ritmo = ritmoJson
      })

      filtros.listarEstilo().then(res => {
        res.forEach(element => {
          estiloJson.push({
            nombre: element.estilo_nombre,
            cod: element.estilo_codigo
          })
        })
        this.filtros.estiloEmpresa = estiloJson
      })
    },
    selectFiltro () {
      this.getPlaylists()
    },
    getPlaylists () {
      const jsonRes = []
      generoMusicalServices.buscar(0, 0, this.filtroSelected.ritmo, this.filtroSelected.tipoEmpresa, this.filtroSelected.estiloEmpresa).then(res => {
        res.forEach(element => {
          if (element.rad_codigo != 0) {
            jsonRes.push({
              data: '{"title": "' + element.rad_nombre + '"}',
              nombre: element.rad_nombre,
              title: element.rad_nombre,
              codRadio: element.rad_codigo,
              img: element.rad_imagen,
              genero: element.genmus_nombre,
              subGenero: element.gemusu_nombre,
              enlanceStream: element.rad_enlace
            })
          }
        })
        this.radios = jsonRes
      })
    },
    getGenerosMusicales () {
      generoMusicalServices.getGeneros().then(res => {
        const resJson = []
        let cont = 0
        res.forEach(element => {
          resJson.push({
            genero: element.genmus_nombre,
            index: cont
          })
          cont = cont + 1
        })

        this.generosMusicales = resJson
      })
    },
    getSubGenerosMusicales () {
      const resJson = []
      generoMusicalSubServices.getSubGeneros().then(res => {
        res.forEach(element => {
          resJson.push({
            subGenero: element.gemusu_nombre,
            genero: element.genmus_nombre
          })
        })
        this.subGeneros = resJson
      })
    },
    getProgramaciones () {
      const programaciones = []
      let fechaInicio = ''
      let fechaFin = ''
      this.isLoading = true
      cliproRadioServices.getProgramacionesByProg(this.codigoProgramacion).then(res => {
        res.forEach(element => {
          fechaInicio = this.armarFecha((element.clprra_numeroDia), parseInt(element.clprra_horaDesde.split(':')[0]),parseInt(element.clprra_horaDesde.split(':')[1]))
          fechaFin = this.armarFecha((element.clprra_numeroDia), parseInt(element.clprra_horaHasta.split(':')[0]), parseInt(element.clprra_horaHasta.split(':')[1]))

          programaciones.push({
            title: element.rad_nombre,
            start: fechaInicio,
            end: fechaFin,
            extendedProps: {
              cod: element.clprra_codigo,
              codRadio: element.rad_codigo,
              radImagen: element.rad_imagen
            }
          })
        })

        this.calendarOptions.events = programaciones
        this.isLoading = false
      })
    },
    armarFecha (numeroDia, hora, minutos) {
      // recibo un numero de día (0-7), 0=domingo, moment me devuelve la fecha que le corresponde
      // al número, en la semana, mes y año actual.
      const fecha = moment(moment().day(numeroDia)).format('YYYY-MM-DD')
      let min = ''
      let hs = ''
      let fechaFullCalendar = ''

      // Hago esto porque el timeSpan pone solo una cifra en hora o minutos si es menor a 10(fullcalendar necesita 2)
      if (hora < 10) {
        hs = '0' + hora
      } else {
        hs = hora
      }

      if (minutos === 0) {
        min = '00'
      } else {
        min = '30'
      }

      // armo la fecha para fullcalendar formato (YYYY-MM-DD HH:MM:SS)
      fechaFullCalendar = fecha + ' ' + hs + ':' + min + ':' + '00'
      return fechaFullCalendar
    },
    guardar (info) {
      const codigoProgramacion = this.codigoProgramacion
      const codigoRadio = parseInt(info.event.extendedProps.codRadio)
      const numeroDia = moment(info.event.start).day()
      let horaIni = moment(info.event.start)
      let horaFin = moment(info.event.end)

      if (horaIni.day() != horaFin.day()) {
        this.notificacion = 'Los eventos deben iniciar y finalizar el mismo día'
        this.notifyVue('top', 'center', this.notificacion)
        info.revert()
      } else {
        horaIni = moment(horaIni).format('YYYY-MM-DD HH:mm:ss')
        horaFin = moment(horaFin).format('YYYY-MM-DD HH:mm:ss')

        this.isLoading = true
        cliproRadioServices.guardarProgRadio(codigoProgramacion, codigoRadio, numeroDia, horaIni, horaFin).then(res => {
          info.revert()
          this.getProgramaciones()
          this.isLoading = false
        }).catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion)
          info.revert()
          this.isLoading = false
        })
      }
    },
    resize (info) {
      const codigo = info.event.extendedProps.cod
      const codigoProgramacion = this.codigoProgramacion
      const codigoRadio = info.event.extendedProps.codRadio
      const numeroDia = moment(info.event.start).day()
      let horaIni = moment(info.event.start)
      let horaFin = moment(info.event.end)

      this.isLoading = true

      if (horaIni.day() != horaFin.day()) {
        this.isLoading = false
        this.notificacion = 'Los eventos deben iniciar y finalizar el mismo día'
        this.notifyVue('top', 'center', this.notificacion)
        info.revert()
      } else {
        horaIni = moment(horaIni).format('YYYY-MM-DD HH:mm:ss')
        horaFin = moment(horaFin).format('YYYY-MM-DD HH:mm:ss')

        cliproRadioServices.editarProgRadio(codigo, codigoProgramacion, codigoRadio, numeroDia, horaIni, horaFin).then(res => {
          this.isLoading = false
          this.getProgramaciones()
        }).catch(err => {
          this.handleError(err)
          this.isLoading = false
          this.notifyVue('top', 'center', this.notificacion)
          info.revert()
        })
      }
    },
    mover (info) {
      const codigo = info.event.extendedProps.cod
      const codigoProgramacion = this.codigoProgramacion
      const codigoRadio = info.event.extendedProps.codRadio
      const numeroDia = moment(info.event.start).day()
      let horaIni = moment(info.event.start)
      let horaFin = moment(info.event.end)

      if (horaIni.day() != horaFin.day()) {
        this.notificacion = 'Los eventos deben iniciar y finalizar el mismo día'
        this.notifyVue('top', 'center', this.notificacion)
        info.revert()
      } else {
        horaIni = moment(horaIni).format('YYYY-MM-DD HH:mm:ss')
        horaFin = moment(horaFin).format('YYYY-MM-DD HH:mm:ss')

        cliproRadioServices.editarProgRadio(codigo, codigoProgramacion, codigoRadio, numeroDia, horaIni, horaFin).then(res => {
          this.getProgramaciones()
        }).catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion)
          info.revert()
        })
      }
    },
    // click sobre un evento
    eliminar (clickInfo) {
      this.$confirm(
        {
          message: 'Confirmar para eliminar ' + clickInfo.event.title + ' del calendario',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              clienteProgramacionRadioService.bajaProgramacionRadio(clickInfo.event.extendedProps.cod).then(res => {
                this.getProgramaciones()
              })
            }
          }
        }
      )
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 502:
              this.notificacion = 'El servidor no se encuentra disponible.'
              break
            default:
              this.notificacion = 'Ocurrió un error al intentar guardar la programación. Intente nuevamente'
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: Notification,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    },
    // click sobre el calendar
    handleDateSelect (selectInfo) {
      console.log(selectInfo)
    },
    handleEvents (events) {
      this.currentEvents = events
    }
  }// cierre methods
}
</script>
<style>
</style>
